import React, { Component } from "react";
import styled from "styled-components";
import WM from "../../Images/Barkh_WM.svg";
import { Margin, SmallMargin } from "../../Sprint/Variables";
import Waypoint from "react-waypoint";

const Background = styled.div`
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  background: white;
  background-size: cover;
  background-position: center;
`;

const Logo = styled.img`
  top: 12px;
  position: fixed;
  width: 150px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
`;
const Menu = styled.div`
  top: 0px;
  left: 0px;
  font-weight: bold;
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 100;
  font-size: 50px;
  line-height: 30px;
`;

const NumberLink = styled.div`
  transition: padding 0.3s ease-in;
  :hover {
    padding: 15px;
    color: yellow;
  }
`;

const SessionParagraph = styled.div`
  max-width: 500px;
  width: 70%;
  margin: 0 auto;
  font-weight: bold;
  font-size: 21px;
`;

const SessionTitle = styled.div`
  font-size: 180px;
  font-weight: 800;
  letter-spacing: 80px;
  word-break: break-all;
`;

const SessionLine = styled.div`
  width: 100%;
  height: 3px;
  background: black;
`;

class Sessions extends Component {
  state = {
    mode: "light"
  };

  render() {
    return (
      <Background
        style={{ background: this.state.mode === "dark" ? "black" : "white" }}
      >
        {this.state.mode === "light" ? <Logo src={WM} /> : null}
        <Menu>
          <div>S01E0</div>
          <div>
            <NumberLink>1</NumberLink>
            <NumberLink>2</NumberLink>
            <NumberLink
              style={{
                opacity: "0.3"
              }}
            >
              3
            </NumberLink>
            <NumberLink
              style={{
                opacity: "0.3"
              }}
            >
              4
            </NumberLink>
          </div>
        </Menu>
        <Margin />
        <Margin />
        <Margin />
        <SessionParagraph
          style={{ color: this.state.mode === "dark" ? "white" : "black" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
          dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
          <SmallMargin />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
          <Waypoint
            onEnter={() => {
              this.setState({ mode: "dark" });
            }}
            onLeave={() => {
              this.setState({ mode: "light" });
            }}
          >
            <Margin />
          </Waypoint>
          <SessionLine />
          S01E03
          <Margin />
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor
          <Margin />
          <SessionTitle>Spegelbild</SessionTitle>
          <Margin />
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor
        </SessionParagraph>
      </Background>
    );
  }
}

export default Sessions;

/*const SoundCloudAudioSource = function(audioElement) {
  const player = document.getElementById(audioElement);
  const self = this;
  const analyser;
  const audioCtx = new (window.AudioContext || window.webkitAudioContext); // this is because it's not been standardised accross browsers yet.
  analyser = audioCtx.createAnalyser();
  analyser.fftSize = 256; // see - there is that 'fft' thing. 
  const source = audioCtx.createMediaElementSource(player); // this is where we hook up the <audio> element
  source.connect(analyser);
  analyser.connect(audioCtx.destination);
  const sampleAudioStream = function() {
      // This closure is where the magic happens. Because it gets called with setInterval below, it continuously samples the audio data
      // and updates the streamData and volume properties. This the SoundCouldAudioSource function can be passed to a visualization routine and 
      // continue to give real-time data on the audio stream.
      analyser.getByteFrequencyData(self.streamData);
      // calculate an overall volume value
      var total = 0;
      for (var i = 0; i < 80; i++) { // get the volume from the first 80 bins, else it gets too loud with treble
          total += self.streamData[i];
      }
      self.volume = total;
  };
  setInterval(sampleAudioStream, 20); // 
  // public properties and methods
  this.volume = 0;
  this.streamData = new Uint8Array(128); // This just means we will have 128 "bins" (always half the analyzer.fftsize value), each containing a number between 0 and 255. 
  this.playStream = function(streamUrl) {
      // get the input stream from the audio element
      player.setAttribute('src', streamUrl);
      player.play();
  }
}*/
