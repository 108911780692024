import styled from "styled-components";
import { primary } from "./Variables";

const SprLink = styled.a`
  display: inline;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;

  :link {
    color: inherit;
  }

  :hover {
    color: ${primary};
  }
  :visited {
    color: ${primary};
  }
`;

export default SprLink;
