import React, { Component } from "react";
import styled from "styled-components";
import {
  containerWidth,
  primary,
  Line,
  ThinLine,
  mediaMaxPhone
} from "../Sprint/Variables";
import WM from "../Images/BARKH_WM_WHITE.svg";
import { IoIosCloseEmpty } from "react-icons/lib/io";
import SprTitle from "../Sprint/SprTitle";
import SprLink from "../Sprint/SprLink";
import { NavLink } from "react-router-dom";

const HeaderHeight = "100px";
export const SmallHeaderHeight = "80px";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background: none;
  z-index: 900;
`;

export const HeaderMargin = styled.div`
  height: ${HeaderHeight};
`;

const HeaderContent = styled.div`
  user-select: none;
  color: white;
  transition: 0.5s;
  max-width: ${containerWidth};
  margin: 0 auto;
  border-bottom: ${props =>
    props.ChangeHeader
      ? "2px solid rgba(0,0,0,1)"
      : "2px solid rgba(0,0,0,0)"};
  height: ${props => (props.ChangeHeader ? HeaderHeight : SmallHeaderHeight)};
  width: 100%;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  @media screen and (max-width: ${containerWidth}) {
    padding: 0;
    width: 90%;
  }
`;

const HeaderBg = styled.div`
  transition: opacity 0.5s ease;
  background: black;
  opacity: ${props => (props.ChangeHeader ? "0" : "0.3")};
  opacity: ${props => (props.mobile ? "1" : "")};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;
const WmIcon = styled.img`
  height: 30px;
`;

const Tabs = styled.div`
  display: flex;
  transition: 0.5s;
  transform: ${props => (props.contactClose ? "translateY(-200px)" : "")};
  
`;


const TabLine = styled.div`
  height: 3px;
  width: 80%;
  margin: 5px auto;
  background: #33cc99;
  transform: translateY(25px);
  transition: 0.2s ease;
  opacity: 0;
`;

const ContactPage = styled.div`
  z-index: 10;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  position: absolute;
  width: 100vw;
  height: calc(
    100vh - ${props => (props.ChangeHeader ? HeaderHeight : SmallHeaderHeight)}
  );
  display: flex;
  opacity: ${props => (props.show ? "1" : "0")};
  visibility: ${props => (props.show ? "visible" : "hidden")};
  justify-content: center;
  flex-direction: column;
`;

const ContactButtonWrappper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

const ContactWrapper = styled.div`
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 500px;
`;


const Tab = styled.div`
  margin-left: 40px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 6px;
  font-weight: bold;
  color: ${props => props.color ? 'black' : 'white'};
  font-size: 14px;
  transition: 0.9s ease;
  cursor:pointer;
  &:hover {
    transform: translateY(3px);
  }
  &:hover ${TabLine} {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const CloseContact = styled.div`
  position: absolute;
  right: 0;
  display: block;
  cursor: pointer;
  transition: 0.5s;
  transform: ${props =>
    props.visible ? "translate(0,0)" : "translate(20px,-20px)"};
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  :hover {
    transform: ${props => (props.visible ? "scale(1.2)" : "")};
  }
`;

const LineWrapper = styled.div`
  &:hover ${Line} {
    transform: scaleX(0.5);
  }
`;

const Logolink = styled.a`
  transition: 0.5s ease;
  transform: ${props =>
    props.hide ? "translateY(-100px)" : "translateY(0px)"};
  opacity: ${props => (props.hide ? "0" : "1")};
  visibility: ${props => (props.hide ? "hidden" : "visible")};
`;

const MoreContactInfo = styled.div`
  box-display: flex;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  color: #666;
  transition: 2s ease;
  width: 100%;
  opacity: ${props => (props.animate ? "1" : "0")};
  transform: ${props =>
    props.animate ? "translateY(0px)" : "translateY(100%)"};
  padding-bottom: 5vh;
  @media screen and (${mediaMaxPhone}) {
    padding-bottom: 20vh;
  }
`;

class MenuContent extends Component {
  render() {
    return (
      <React.Fragment>
        <Tab onClick={this.props.showContactMenu} color={this.props.color}>
          Contact
          <TabLine />
        </Tab>
      </React.Fragment>
    );
  }
}

class Header extends Component {
  state = {
    isTop: true,
    mobileMenu: false,
    contactMenu: false
  };

  showMobileMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu
    });
    this.setState({ contactMenu: false });

    console.log(this.state.mobileMenu);
  };
  showContactMenu = () => {
    this.setState({
      contactMenu: !this.state.contactMenu
    });
    this.setState({ mobileMenu: false });

    console.log(this.state.contactMenu);
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <HeaderBg
          ChangeHeader={this.state.isTop}
          mobile={this.state.mobileMenu || this.state.contactMenu}
        />
        <HeaderContent ChangeHeader={this.state.isTop}>
          <NavLink to="/">
          </NavLink>


          <Tabs contactClose={this.state.contactMenu}>
            <MenuContent showContactMenu={this.showContactMenu} color={this.state.isTop}/>
          </Tabs>

          <CloseContact
            onClick={this.showContactMenu}
            visible={this.state.contactMenu}
          >
            <IoIosCloseEmpty
              size={40}
              style={{
                cursor: "pointer",
                color: "white"
              }}
            />
          </CloseContact>
        </HeaderContent>
        <ContactPage show={this.state.contactMenu}>
          <HeaderBg mobile={this.state.contactMenu} />
          <ContactButtonWrappper>
            <ContactWrapper>
              <SprTitle huge white>
                <LineWrapper>
                  <SprLink href={"mailto:info@barkh.se"}>MAIL</SprLink>
                  <Line
                    style={{
                      background: primary,
                      transition: "0.3s"
                    }}
                  />
                </LineWrapper>
              </SprTitle>
            </ContactWrapper>
            <ContactWrapper>
              <SprTitle huge white>
                <LineWrapper>
                  <SprLink href={"tel:+46709707970"}>CALL</SprLink>
                  <Line
                    style={{
                      background: primary,
                      transition: "0.3s"
                    }}
                  />
                </LineWrapper>
              </SprTitle>
            </ContactWrapper>
          </ContactButtonWrappper>
          <MoreContactInfo animate={this.state.contactMenu}>
            <div>
              <div>
                <SprLink href="mailto:info@barkh.se">info@barkh.se</SprLink>
              </div>
              <ThinLine
                style={{ width: 30, margin: "7px auto", background: "#444" }}
              />
            </div>
          </MoreContactInfo>
        </ContactPage>
      </Wrapper>
    );
  }
}

export default Header;
