import React, { Component } from "react";
import styled from "styled-components";
import {
  containerWidth,
  shadowSmall,
  dark,
  mediaMaxPhone,
  SmallMargin,
} from "../Sprint/Variables";



const Wrapper = styled.div`
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background: ${dark};
  z-index: 100;
`;

const TextContainer = styled.div`
  justify-content: space-between;
  display: flex;
  transition: 0.5s ease;
  max-width: ${containerWidth};
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (${mediaMaxPhone}) {
    flex-direction: column;
  }
`;

const FlexContainer = styled.div`
  box-sizing: border-box;
  color: white;
  font-weight: 100;
  line-height: 1.7;
  font-size: 11px;
  width: 100%;
  max-width: 300px;
  padding: 30px 0;
  @media screen and (max-width: ${containerWidth}) {
    padding: 30px;
  }
`;


class Footer extends Component {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <Wrapper>
        <TextContainer
          style={{
            alignItems: "center",
          }}
        >
          <FlexContainer
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              fontSize: "14px",
              maxWidth: "100%"
            }}
          >
            <div>Barkh Sweden AB - Copyright © {this.getYear()}</div>
          </FlexContainer>
        </TextContainer>
      </Wrapper>
    );
  }
}

export default Footer;
