import React, { Component } from "react";
import WM from "../Images/Barkh_WM.svg";
class LandingPage extends Component {
  render() {
    return (
      <div style={{display:'flex', alignItems: 'center', flexDirection: 'column', justifyContent:'center', minHeight: '100vh'}}>
      <img src={WM} alt="BARKH" style={{width:'30%', margin:'0 auto'}}/>
      </div>
    );
  }
}

export default LandingPage;
