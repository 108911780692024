import React, { Component } from "react";
import styled from "styled-components";
import { background, dark } from "../Sprint/Variables";

const Container = styled.div`
  background-color: #fefefe;
  min-width: 100vw;
  overflow: hidden;
  position: absolute;
`;



class SiteContainer extends Component {
  render() {
    return (
      <Container>
        {this.props.children}
      </Container>
    );
  }
}

export default SiteContainer;
