import React, { Component } from "react";
import App from "./App";
import Sessions from "./Projects/Sessions/Sessions";
import NotFound from "./Global/NotFound";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";

class BarkhRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <ScrollContext>
              <App />
            </ScrollContext>
          </Route>
          <Route path="/sessions">
            <ScrollContext>
              <Sessions />
            </ScrollContext>
          </Route>
          <Route>
            <ScrollContext>
              <NotFound />
            </ScrollContext>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default BarkhRouter;
