import React, { Component } from "react";
import TreeBg from "../Images/tree.jpg";
import { IoIosArrowThinRight } from "react-icons/lib/io";
import styled from "styled-components";
import SprTitle from "../Sprint/SprTitle";
import SprLink from "../Sprint/SprLink";
import { Margin, containerWidth, SmallMargin } from "../Sprint/Variables";

import Footer from "./Footer";

const Container = styled.div`
  max-width: ${containerWidth};
  margin: 0 auto;
  height: 700px;
  background: url(${TreeBg});
  background-size: auto 700px;
  background-repeat: no-repeat;
  padding-left: 300px;
  padding-top: 100px;
  box-sizing: border-box;
  @media screen and (max-width: 700px) {
    padding: 100px 20px;

    background: none;
  }
`;

class NotFound extends Component {
  render() {
    return (
      <div style={{ background: "white" }}>
        <Container>
          <SprTitle dark huge>
            404
          </SprTitle>
          <SprTitle gray medium>
            <p>Lost in the woods?</p>
            <p>We can’t find what you’re looking for.</p>
          </SprTitle>
          <SmallMargin />
          <SmallMargin />
          <SprTitle small>
            <SprLink href={"/"}>
              Find your way back home
              <IoIosArrowThinRight size={45} />
            </SprLink>

            <Margin />
            <Margin />
          </SprTitle>
        </Container>

        <Footer />
      </div>
    );
  }
}

export default NotFound;
