import React, {Component} from "react";
import SiteContainer from "./Global/SiteContainer";
import Header, {HeaderMargin} from "./Global/Header";
import Footer from "./Global/Footer";
import LandingPage from "./Home/LandingPage";
import Conny from "./Images/ConnyEdit.jpg";

class App extends Component {
  componentDidMount() {
    document.title = "BARKH";
  }
  constructor(props) {
    super(props);
    this.state = {
      showArticle: false,
      whatToShow: 0,
      showEverything: false,
      activeID: null,
      voted: null,
      showClose: false
    };
  }

  setshowEverything = index => {
    this.setState({showEverything: true});
  };

  blockVotes = vote => {
    this.setState({voted: vote});
  };

  show = (index, id) => {
    this.setState({
      voted: null,
      showEverything: false,
      showArticle: true,
      whatToShow: index,
      activeID: id,
      showClose: false
    });
  };
  hide = () => {
    this.setState({showArticle: false, showEverything: false});
    window.scrollTo(0, 700);

  };

  showCloseFunc = () => {
    this.setState({showClose: true});
  };
  hideCloseFunc = () => {
    this.setState({showClose: false});
  };

  render() {
    return (
      <SiteContainer backgroundImg={Conny}>
        <Header
          hide={this.hide}
          whatToShow={this.state.whatToShow}
          showCloseBtn={this.state.showClose && this.state.showEverything}/>
        <LandingPage/>
      </SiteContainer>
    );
  }
}

export default App;
