import styled, { keyframes } from "styled-components";
import React, { Component } from "react";
import { WhenInView } from "./WhenInView";

export const mediaMax = "max-width: 800px";
export const mediaMaxOld = "max-width: 340px";
export const mediaMaxPhone = "max-width: 500px";

export const background = "rgb(76,76,76)";
export const primary = "#33cc99";
export const dark = "#292929";
export const lightdark = "#555";
export const light = "#bbb";
export const lighter = "#ddd";
export const lightest = "#eee";
export const gray = "#9B9B9B";
export const green = "#78EC71";
export const red = "#FF7586";
export const yellow = "#FFE400";

export const shadow = "0px 0px 150px -5px rgba(0,0,0,0.2)";
export const shadowHover = "0px 0px 180px -5px rgba(0,0,0,0.5)";
export const shadowSmall = "0px 0px 15px 1px rgba(0,0,0,0.23)";
export const sRadius = "5px";
export const bRadius = "15px";

export const bounce = "cubic-bezier(0.67, 2.13, 0.5, 0.49)";
export const spring = "cubic-bezier(0.32,-0.33, 0, 0.63)";

export const containerWidth = "1100px";

export const StandardPadding = "10%";
export const Margin = styled.div`
  height: 100px;
`;
export const SmallMargin = styled.div`
  height: 20px;
`;

export const PrimarySpan = styled.span`
  color: ${primary};
`;

export const Line = styled.div`
  background: ${dark};
  height: 5px;
  width: 100%;
`;

export const LineVertical = styled.div`
  background: ${dark};
  width: 5px;
  height: 100%;
`;

export const ThinLine = styled.div`
  background: ${lighter};
  height: 1px;
  width: 100%;
`;
export const Separator = styled.div`
  height: 2px;
  width: 90%;
  max-width: 400px;
  min-width: 50px;
  margin: 0 auto;
`;
export const SmallSeparator = styled.div`
  background: ${light};
  height: 2px;
  width: 30%;
  max-width: 120px;
  min-width: 40px;
  margin: 0 auto;
`;

// Animations

export const BounceRight = keyframes`
20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
`;

export const ClickBounce = keyframes`
  from,
  11.1%,
  to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
`;

export const spinner = keyframes` 
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
`;

export class FakeContent extends Component {
  render() {
    return (
      <div
        style={{
          height: 500,
          width: "100%",
          ...this.props.style
        }}
      />
    );
  }
}

const Circle = styled.div`
  transition: 1s ease-in-out;
  height: 3px;
  width: 3px;
  border-radius: 1px;
  background: ${lighter};
`;

const CircleFirst = Circle.extend`
  transform: ${props =>
    props.animate ? "translateX(0px)" : "translateX(5px)"};
`;
const CircleLast = Circle.extend`
  transform: ${props =>
    props.animate ? "translateX(0px)" : "translateX(-5px)"};
`;

export class SeparatorCircles extends Component {
  render() {
    return (
      <WhenInView>
        {({ isInView }) => (
          <div
            style={{
              margin: "0 auto",
              width: 60,
              justifyContent: "space-between",
              display: "flex"
            }}
          >
            <CircleFirst animate={isInView} />
            <Circle />
            <CircleLast animate={isInView} />
          </div>
        )}
      </WhenInView>
    );
  }
}
