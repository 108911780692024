import React, { Component } from "react";
import styled from "styled-components";
import { primary, dark, gray, mediaMaxPhone, lighter } from "./Variables";

const Title = styled.div`
transform: translate3d(0, 0, 0);


font-family: 'europa',  sans-serif;
  color: ${props => (props.white ? "white" : primary)};
  color: ${props => (props.dark ? dark : "")};
  color: ${props => (props.gray ? gray : "")};
  color: ${props => (props.lighter ? lighter : "")};
  line-height:${props => (props.MoreLineHeight ? "1.8" : "")};

  display: ${props => (props.inlineBlock ? "inline-block" : "")};

    font-weight: ${props => (props.regular ? "regular" : "bold")};

  font-size: ${props => {
    if (props.small) {
      return "16px";
    } else if (props.mini) {
      return "14px";
    } else if (props.huge) {
      return "80px";
    } else if (props.medium) {
      return "23px";
    } else if (props.big) {
      return "35px";
    } else {
      return "50px";
    }
  }};


  @media screen and (${mediaMaxPhone}) {
    font-size: ${props => {
      if (props.small) {
        return "";
      } else if (props.mini) {
        return "";
      } else if (props.huge) {
        return "50px";
      } else if (props.medium) {
        return "20px";
      } else if (props.big) {
        return "28px";
      } else {
        return "40px";
      }
    }};
`;

class SprTitle extends Component {
  render() {
    return (
      <Title
        style={{
          ...this.props.style
        }}
        white={this.props.white}
        dark={this.props.dark}
        gray={this.props.gray}
        lighter={this.props.lighter}
        small={this.props.small}
        mini={this.props.mini}
        medium={this.props.medium}
        huge={this.props.huge}
        big={this.props.big}
        inlineBlock={this.props.inlineBlock}
        regular={this.props.regular}
        MoreLineHeight={this.props.MoreLineHeight}
      >
        {this.props.children}
      </Title>
    );
  }
}

export default SprTitle;
